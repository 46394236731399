import React, { useState, useCallback } from 'react';
import { Server, Cloud, Globe, Monitor, Terminal, Database, Shield, RefreshCw, Box, Phone, CreditCard, Users, Maximize, Minimize, Printer } from 'lucide-react';

const NetworkNode = ({ icon: Icon, title, x, y, color }) => {
  return (
    <div 
      className="absolute transform -translate-x-1/2 -translate-y-1/2 cursor-pointer group print:cursor-default"
      style={{ left: `${x}%`, top: `${y}%` }}
    >
      <div className={`flex flex-col items-center ${y === 100 ? 'pb-8' : ''}`}>
        <div className={`p-4 rounded-full ${color} shadow-lg transition-transform duration-300 transform group-hover:scale-110 
          print:shadow-none print:transform-none print:${color.replace('bg-', 'text-')}`}>
          <Icon size={32} className="text-white print:text-current" />
        </div>
        <span className="mt-2 text-sm font-medium text-gray-300 text-center print:text-gray-900">{title}</span>
      </div>
    </div>
  );
};

const Connection = ({ start, end, dashed, label, labelOffset = { x: 0, y: 0 }, curved = false }) => (
  <svg className="absolute top-0 left-0 w-full h-full pointer-events-none">
    {curved ? (
      <path
        d={`M ${start.x}% ${start.y}% Q ${(start.x + end.x) / 2}% ${Math.max(start.y, end.y) + 8}% ${end.x}% ${end.y}%`}
        fill="none"
        stroke="#4B5563"
        strokeWidth="1.5"
        strokeOpacity="0.4"
        strokeDasharray={dashed ? "5,5" : ""}
        className="print:stroke-gray-700 print:stroke-opacity-100"
      />
    ) : (
      <line
        x1={`${start.x}%`}
        y1={`${start.y}%`}
        x2={`${end.x}%`}
        y2={`${end.y}%`}
        stroke="#4B5563"
        strokeWidth="1.5"
        strokeOpacity="0.4"
        strokeDasharray={dashed ? "5,5" : ""}
        className="print:stroke-gray-700 print:stroke-opacity-100"
      />
    )}
    {label && (
      <text
        x={`${(start.x + end.x) / 2 + labelOffset.x}%`}
        y={`${(start.y + end.y) / 2 + labelOffset.y}%`}
        dy="-4"
        textAnchor="middle"
        fill="#9CA3AF"
        fontSize="12"
        className="select-none print:fill-gray-600"
        stroke="#111827"
        strokeWidth="4"
        strokeOpacity="0.9"
        paintOrder="stroke"
      >
        {label}
      </text>
    )}
  </svg>
);

const NetworkDiagram = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = useCallback(async () => {
    if (!isFullscreen) {
      try {
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
          await elem.requestFullscreen();
        }
        setIsFullscreen(true);
      } catch (err) {
        console.error('Error attempting to enable fullscreen:', err);
      }
    } else {
      try {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        }
        setIsFullscreen(false);
      } catch (err) {
        console.error('Error attempting to exit fullscreen:', err);
      }
    }
  }, [isFullscreen]);

  const handlePrint = useCallback(() => {
    window.print();
  }, []);

  const nodes = [
    { icon: Monitor, title: "Client Access", x: 50, y: 10, color: "bg-yellow-500" },
    { icon: Globe, title: "Cloudflare DNS/CDN", x: 50, y: 25, color: "bg-blue-500" },
    { icon: Box, title: "Vercel", x: 50, y: 40, color: "bg-black" },
    { icon: Phone, title: "Five9 Phone System", x: 50, y: 55, color: "bg-cyan-500" },
    { icon: Users, title: "Call Center Agents", x: 50, y: 70, color: "bg-pink-500" },
    { icon: Cloud, title: "AWS Canada-Central", x: 20, y: 40, color: "bg-orange-500" },
    { icon: Cloud, title: "Google Cloud US-Central", x: 80, y: 40, color: "bg-green-500" },
    { icon: Server, title: "OPAL Server (AWS)", x: 10, y: 85, color: "bg-purple-500" },
    { icon: Server, title: "API Server (AWS)", x: 30, y: 85, color: "bg-purple-500" },
    { icon: Server, title: "API Backup Server (GCE)", x: 70, y: 85, color: "bg-indigo-500" },
    { icon: Server, title: "OPAL Backup Server (GCE)", x: 90, y: 85, color: "bg-indigo-500" },
    { icon: CreditCard, title: "Chase Payment Services", x: 50, y: 100, color: "bg-emerald-500" }
  ];

  const connections = [
    // Top-level connections
    { start: { x: 50, y: 10 }, end: { x: 50, y: 25 }, dashed: false, label: "Requests via" },
    { start: { x: 50, y: 25 }, end: { x: 50, y: 40 }, dashed: false, label: "Routes to" },
    
    // Vercel API connections
    { start: { x: 50, y: 40 }, end: { x: 30, y: 85 }, dashed: false, label: "API Calls" },
    { start: { x: 50, y: 40 }, end: { x: 70, y: 85 }, dashed: true, label: "Backup API" },
    
    // Five9 API connections
    { start: { x: 50, y: 55 }, end: { x: 10, y: 85 }, dashed: false, label: "API Calls" },
    { start: { x: 50, y: 55 }, end: { x: 90, y: 85 }, dashed: true, label: "Backup API" },
    
    // Call Center Agent connections
    { start: { x: 50, y: 70 }, end: { x: 50, y: 55 }, dashed: false, label: "Users" },
    { start: { x: 50, y: 70 }, end: { x: 10, y: 85 }, dashed: false, label: "Primary Access" },
    { start: { x: 50, y: 70 }, end: { x: 90, y: 85 }, dashed: true, label: "Backup Access" },
    
    // Payment processing connections
    { start: { x: 30, y: 85 }, end: { x: 50, y: 100 }, dashed: false, label: "Payment Processing" },
    { start: { x: 70, y: 85 }, end: { x: 50, y: 100 }, dashed: false, label: "Payment Processing" },
    
    // AWS hosting connections
    { start: { x: 20, y: 40 }, end: { x: 20, y: 62.5 }, dashed: false, label: "Hosts" },
    { start: { x: 20, y: 62.5 }, end: { x: 10, y: 85 }, dashed: false },
    { start: { x: 20, y: 62.5 }, end: { x: 30, y: 85 }, dashed: false },
    
    // Google Cloud hosting connections
    { start: { x: 80, y: 40 }, end: { x: 80, y: 62.5 }, dashed: false, label: "Hosts" },
    { start: { x: 80, y: 62.5 }, end: { x: 70, y: 85 }, dashed: false },
    { start: { x: 80, y: 62.5 }, end: { x: 90, y: 85 }, dashed: false },
    
    // Internal communications
    { start: { x: 10, y: 85 }, end: { x: 30, y: 85 }, dashed: false, label: "Internal Comm" },
    { start: { x: 70, y: 85 }, end: { x: 90, y: 85 }, dashed: false, label: "Internal Comm" },
    
    // Sync connections
    { start: { x: 10, y: 85 }, end: { x: 90, y: 85 }, dashed: true, label: "Sync" },
    { start: { x: 30, y: 85 }, end: { x: 70, y: 85 }, dashed: true, label: "Sync" }
  ];

  return (
    <div className="relative w-full bg-gray-900 overflow-hidden flex flex-col h-[130vh] print:bg-white print:min-h-0">
      <div className={`flex-grow relative w-full ${isFullscreen ? '' : 'pt-8'} pb-24 min-h-[110vh] overflow-x-auto 
        print:min-h-0 print:overflow-visible print:p-0`}>
        <div className="absolute inset-0 w-[1200px] h-[800px] mt-[-5px] m-auto print:relative print:w-full print:h-auto print:m-0">
          {connections.map((conn, index) => (
            <Connection key={index} {...conn} />
          ))}
          {nodes.map((node, index) => (
            <NetworkNode key={index} {...node} />
          ))}
        </div>
        <button
          onClick={toggleFullscreen}
          className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-700 transition-colors duration-200 bg-gray-800 bg-opacity-75 print:hidden hidden"
          title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
        >
          {isFullscreen ? (
            <Minimize className="text-white w-6 h-6" />
          ) : (
            <Maximize className="text-white w-6 h-6" />
          )}
        </button>
        <button
          onClick={handlePrint}
          className="absolute top-4 right-16 p-2 rounded-full hover:bg-gray-700 transition-colors duration-200 bg-gray-800 bg-opacity-75 print:hidden hidden"
          title="Print diagram"
        >
          <Printer className="text-white w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default NetworkDiagram;