import React from 'react';

const SectionCard = ({ title, children }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-6">
    <h3 className="text-xl font-semibold mb-4 text-gray-800 border-b pb-2">{title}</h3>
    {children}
  </div>
);

const ListItem = ({ title, children }) => (
  <li className="mb-3">
    <span className="font-semibold text-gray-700">{title}: </span>
    {children}
  </li>
);

const NetworkInfo = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="max-w-5xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Chairman's Brands Network Structure Breakdown</h1>
        
        <SectionCard title="Overview">
          <p className="text-gray-600 mb-4">
            The network is built around OPAL, our central order processing system, which handles all core business operations. The infrastructure consists of primary and backup systems spread across AWS Canada-Central and Google Cloud US-Central regions, with Cloudflare providing DNS and CDN services.
          </p>
          <p className="text-gray-600">
            Real-time communications flow through the Hotline Web server, which acts as an intermediary between OPAL and client-facing services. The frontend, hosted on Vercel, communicates with the web server through API calls to deliver the customer-facing websites.
          </p>
        </SectionCard>

        <SectionCard title="1. Platform Services">
          <ul className="list-none space-y-2">
            <ListItem title="Cloudflare DNS/CDN">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Domain: 241pizza.com & NewOrleansPizza.com</li>
                <li>Services: DNS, CDN</li>
                <li>Registrar: Godaddy</li>
              </ul>
            </ListItem>
            <ListItem title="Vercel">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Purpose: Frontend hosting and deployment platform</li>
                <li>Framework: React</li>
                <li>Deployment: Auto-deploy from Git</li>
                <li>Communicates with Hotline Web server via API</li>
                <li>Serves customer-facing websites</li>
                <li>Public IP: 76.76.21.21</li>
                <li>CNAME: cname.vercel-dns.com </li>
              </ul>
            </ListItem>
          </ul>
        </SectionCard>

        <SectionCard title="2. Infrastructure Providers">
          <ul className="list-none space-y-4">
            <ListItem title="AWS Canada-Central (Primary)">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Region: ca-central-1</li>
                <li>VPC: Yes</li>
                <li>Firewall: AWS Firewall</li>
                <li>Hosts primary OPAL and Web servers</li>
              </ul>
            </ListItem>
            <ListItem title="Google Cloud US-Central (Backup)">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Region: us-central1</li>
                <li>Firewall: GC Firewall</li>
                <li>Hosts backup OPAL and Web servers</li>
              </ul>
            </ListItem>
          </ul>
        </SectionCard>

        <SectionCard title="3. Core Systems">
          <ul className="list-none space-y-4 mb-6">
            <ListItem title="OPAL (Primary)">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Central order processing system</li>
                <li>Handles all core business operations</li>
                <li>Type: t3a.xlarge</li>
                <li>Public IP: 15.223.145.237 (Elastic IP)</li>
                <li>DNS: tfo.opalonline.ca</li>
                <li>Hosts pxplus database</li>
                <li>Real-time communication with Hotline Web</li>
              </ul>
            </ListItem>
            <ListItem title="API Gateway (Primary)">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>API gateway for frontend services</li>
                <li>Real-time communication with OPAL</li>
                <li>Type: t2.medium</li>
                <li>Public IP: 15.223.79.203 (Elastic IP)</li>
                <li>DNS: tfoapi.webordering.ca:5001 / nopapi.webordering.ca:5001</li>
                <li>Handles frontend API requests</li>
              </ul>
            </ListItem>
          </ul>

          <h4 className="font-semibold mb-3 text-gray-700">Backup Systems</h4>
          <ul className="list-none space-y-4">
            <ListItem title="OPAL Backup">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Failover order processing system</li>
                <li>Type: e2-medium</li>
                <li>IP: 35.188.81.184 (Static)</li>
                <li>DNS: tfo3.opalonline.ca</li>
                <li>Syncs with primary OPAL</li>
              </ul>
            </ListItem>
            <ListItem title="Web Backup">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Backup API gateway</li>
                <li>Type: e2-medium</li>
                <li>Public IP: 34.70.129.17 (Static)</li>
                <li>DNS: tfoapi2.webordering.ca / nopapi2.webordering.ca (failover)</li>
                <li>Syncs with primary Web server</li>
              </ul>
            </ListItem>
          </ul>
        </SectionCard>

        <SectionCard title="4. Security">
          <ul className="list-disc pl-5 space-y-2 text-gray-600">
            <li>AWS Firewall and GC Firewall protecting respective servers</li>
            <li>Open ports: 22, 3000, 3443, 80, 443</li>
          </ul>
        </SectionCard>

        <SectionCard title="5. Data Synchronization">
          <ul className="list-none space-y-4">
            <ListItem title="OPAL Sync: AWS OPAL to GCS OPAL Backup">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Critical files: Every 15 minutes</li>
                <li>All files: Daily</li>
                <li>Method: rsync over SSH</li>
              </ul>
            </ListItem>
            <ListItem title="Web Sync: AWS Web to GCS Web Backup">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Frequency: Every 15 minutes</li>
                <li>Method: rsync over SSH</li>
              </ul>
            </ListItem>
          </ul>
        </SectionCard>

        <SectionCard title="6. Call Center Agent Connectivity">
          <ul className="list-none space-y-4">
            <ListItem title="Five9 Connection">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>VPN: Five9 Secure Connect required</li>
                <li>Software: Five9 Agent Desktop Plus</li>
                <li>Authentication: Individual Five9 credentials</li>
                <li>Phone: Five9 softphone integration</li>
              </ul>
            </ListItem>
            <ListItem title="Primary OPAL Connection">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Server: OPAL (AWS)</li>
                <li>Connection Point: tfo.opalonline.ca</li>
                <li>Client Software: WindX Terminal</li>
                <li>Authentication: Individual agent credentials</li>
              </ul>
            </ListItem>
            <ListItem title="Backup OPAL Connection">
              <ul className="list-disc pl-5 mt-2 space-y-1 text-gray-600">
                <li>Server: OPAL Backup (GCS)</li>
                <li>Connection Point: tfo3.opalonline.ca</li>
                <li>Failover: Automatic DNS redirect during primary outage</li>
                <li>Same credentials as primary system</li>
              </ul>
            </ListItem>
          </ul>
        </SectionCard>

        <SectionCard title="7. Client Access">
          <ul className="list-disc pl-5 space-y-2 text-gray-600">
            <li>Web Access (browser):  241pizza.com & NewOrleansPizza.com (via Vercel)</li>
            <li>OPAL Access: WindX Client to tfo.opalonline.ca</li>
          </ul>
        </SectionCard>
      </div>
    </div>
  );
};

export default NetworkInfo;