import React, { useState } from 'react';
import NetworkDiagram from './NetworkDiagram';
import NetworkInfo from './NetworkInfo';
import PasswordProtection from './PasswordProtection';

function App() {
  const [showInfo, setShowInfo] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Chairman's Brands Network Infrastructure</h1>
          {isAuthenticated && (
            <button
              onClick={() => setShowInfo(!showInfo)}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              {showInfo ? 'Show Diagram' : 'Show Info'}
            </button>
          )}
        </div>
        {!isAuthenticated ? (
          <PasswordProtection onAuthenticated={() => setIsAuthenticated(true)} />
        ) : (
          showInfo ? <NetworkInfo /> : <NetworkDiagram />
        )}
      </div>
    </div>
  );
}

export default App;